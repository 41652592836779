import * as React from 'react'
import { graphql } from 'gatsby'
import TabPanel from '../components/tabs'
import { myContext } from '../../provider'
import { PrimaryArticle, SecondaryArticle, AllArticle } from '../components/articles/index-articles'
import { StaticImage } from "gatsby-plugin-image"

const Index = ({ data }) => {
    console.log("Index data : ", data);
    return (
            <myContext.Consumer>
                {context => (
                    <>
                    <div className="lg:relative">
                      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                          <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            <span className="block xl:inline">Discover your bliss</span>{' '}
                          </h1>
                          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                          Club Mella is the premier destination for sex-positive couples interested in discovering their bliss. 
                          </p>
                          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                              <a
                                href="#"
                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                              >
                                Join Now 
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                        <img
                          className="absolute inset-0 w-full h-full object-cover"
                          src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                          alt=""
                        />
                      </div>
                    </div>

                    <section>
                      <div className="container mt-2">
                        <h2>Featured Articles</h2>
                        <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 md:flex-row mt-6 md:space-x-12">
                          <AllArticle data={data.gogobackend.all}/>
                        </div>
                      </div>
                    </section>

                    <section>
                      <div className="relative bg-gray-800">
                        <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                          <img
                            className="w-full h-full object-cover"
                            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
                            alt=""
                          />
                        </div>
                        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                          <div className="md:ml-auto md:w-1/2 md:pl-10">
                            <p className="mt-2 text-white text-3xl font-bold tracking-tight sm:text-4xl">Dating app for couples</p>
                            <p className="mt-3 text-lg text-gray-300">
                            Meet like-minded singles and couples who are interested in finding new friends and lovers. Granular privacy settings allow you to choose exactly who sees your profile, photos, videos, and journal entries. 
                            </p>
                            <div className="mt-8">
                              <div className="inline-flex rounded-md shadow">
                                Learn More
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section>
                      <div className="container mt-2">
                        <div className="relative">
                          <div className="sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                          <StaticImage
                            src="../images/image_index3.png"
                            alt="A dinosaur"
                            placeholder="blurred"
                            layout="fixed"
                          />
                          </div>
                          <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                            <div className="md:ml-auto md:w-1/2 md:pl-10">
                              <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Dating app for couples</p>
                              <p className="mt-3 text-lg text-gray-300">
                                Meet like-minded singles and couples who are interested in finding new friends and lovers. Granular privacy settings allow you to choose exactly who sees your profile, photos, videos, and journal entries. 
                              </p>
                              <div className="mt-8">
                                <div className="inline-flex rounded-md shadow">
                                  Learn More
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    </>
                )}
            </myContext.Consumer>
    )
}

export const queryPrimary = graphql`
query {
  gogobackend {
      primary: news_articles_content(
        status: "Published"
        page_location: "primary"
        limit: 1
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
    }
      secondary: news_articles_content(
        status: "Published"
        page_location: "secondary"
        limit: 2
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
    }
      all: news_articles_content(
        status: "Published"
        limit: 3
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
    }
  }
`

export default Index